import * as Backend from "@faintlines/backend-client";

import React, { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	FormGroup,
	FormFeedback,
	Container,
	Col,
	Row,
} from "reactstrap";
import { useFormik, Field, FormikProvider } from "formik";
import * as Yup from "yup";
import sha256 from "sha256";

const PASSWORD_SALT = "B6k#b8sAx9TV7ETtUe";

function Login1({
	submitText,
	unexpectedErrorMessage,
	hashCred,
	frontendLogin,
	onLogin,
}) {
	const { error, submitHandler } = useLogin({
		unexpectedErrorMessage,
		frontendLogin,
		hashCred,
		onLogin,
	});

	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			username: Yup.string().max(50).required("Username is required"),
			password: Yup.string().max(255).required("Password is required"),
		}),
		enableReinitialize: true,
		onSubmit: submitHandler,
	});

	const { errors, touched, handleSubmit } = formik;

	return (
		<FormikProvider value={formik}>
			<Container>
				<Row>
					<Col className="ml-auto mr-auto" md="5">
						<Card className="card-login card-plain">
							<Form className="form" onSubmit={handleSubmit}>
								<CardBody>
									<FormGroup className="input-lg">
										<Input
											tag={Field}
											placeholder="Username"
											name="username"
											type="text"
											component="input"
											invalid={
												errors.username &&
												touched.username
											}
										/>
										<FormFeedback>
											{errors.username}
										</FormFeedback>
									</FormGroup>
									<FormGroup className="input-lg">
										<Input
											placeholder="Password"
											type="password"
											tag={Field}
											name="password"
											component="input"
											invalid={
												errors.password &&
												touched.password
											}
										/>
										<FormFeedback>
											{errors.password}
										</FormFeedback>
									</FormGroup>
								</CardBody>
								<CardFooter className="text-center">
									<FormGroup>
										<Button
											block
											type="submit"
											className="btn-round"
											color="info"
											size="lg"
										>
											{submitText}
										</Button>
										<FormFeedback>{error}</FormFeedback>
									</FormGroup>
								</CardFooter>
							</Form>
						</Card>
					</Col>
				</Row>
			</Container>
		</FormikProvider>
	);
}
Login1.defaultProps = {
	submitText: "Sign In",
	unexpectedErrorMessage: "Failed logging in, please try again.",
};

export default Login1;

function useLogin({
	unexpectedErrorMessage,
	frontendLogin,
	hashCred,
	onLogin,
}) {
	const [error, setError] = useState(null);

	const submitHandler = frontendLogin
		? frontendSubmit(hashCred, unexpectedErrorMessage, setError, onLogin)
		: backendSubmit(unexpectedErrorMessage, setError, onLogin);

	return { error, submitHandler };
}

function backendSubmit(unexpectedErrorMessage, setError, onLogin) {
	return (values, actions) => {
		setError(null);
		Backend.storyInteract({
			interactionType: "login",
			data: values,
		})
			.then(({ data }) => {
				if (data.success) {
					onLogin(data.session_id);
				} else {
					setError(data.error || unexpectedErrorMessage);
				}
			})
			.catch(() => {
				setError(unexpectedErrorMessage);
			})
			.finally(() => {
				actions.setSubmitting(false);
			});
	};
}

function frontendSubmit(hashCred, unexpectedErrorMessage, setError, onLogin) {
	return ({ username, password }) => {
		if (
			getSha256String(username) === hashCred.username &&
			getSha256String(password) === hashCred.password
		) {
			onLogin(true);
		} else {
			setError(unexpectedErrorMessage);
		}
	};
}

function getSha256String(string) {
	return sha256(string.trim().toLowerCase() + PASSWORD_SALT);
}
